import React from "react"
import * as styles from "./success.module.css"
import Lottie from 'react-lottie'
import animationData from '../../animations/success.json'

function MembershipSuccess(props) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return <section className={styles.wrapper}>
        <h2 className={`titleWhite`}>{props.title}</h2>
        <Lottie
            options={defaultOptions}
            style={{ width: 300, height: 300, margin: 'auto' }}
        />
        <h3 className={styles.subtitle}>{props.subtitle}</h3>
        <p className={styles.text}>{props.text}</p>
    </section>;
}

export default MembershipSuccess;