import React from "react"
import * as styles from "./social.module.css"

import imgFacebook from "./../../images/facebook.svg"
import imgInstagram from "./../../images/instagram.svg"
import imgTwitter  from "./../../images/twitter.svg"
import imgMail from "./../../images/mail.svg"

function Social() {
    return (
        <div className={styles.social}>
            <a href="https://www.facebook.com/movedungarvan/"><img src={imgFacebook} alt={'Facebook'}/></a>
            <a href="https://www.instagram.com/movedungarvan/"><img src={imgInstagram} alt={'Instagram'}/></a>
            <a href="https://twitter.com/MoveDungarvan"><img src={imgTwitter} alt={'Twitter'}/></a>
            <a href="mailto:info@move-dungarvan.ie"><img src={imgMail} alt={'Mail'}/></a>
        </div>
    )
}

export default Social