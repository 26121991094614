import React from "react"
import Seo from "../components/seo/seo"
import Footer from "../components/footer/footer"
import Nav from "../components/nav/nav"
import Success from "../components/success/success"

import { useQueryParam } from "gatsby-query-params"

function SuccessScreen() {

  const title = useQueryParam("title", "Success")
  const subtitle = useQueryParam("subtitle", "Thank You For Purchasing")
  const text = useQueryParam("text", "We have sent the program to your email.")

  return (
    <Seo title={title} noIndex={true}>
      <Success
        title={title}
        subtitle={subtitle}
        text={text} />
      <Footer />
      <Nav showLogo={true} />
    </Seo>
  )
}

export default SuccessScreen;
