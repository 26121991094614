import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon16x16 from "../../images/favicon/favicon-16x16.png";
import favicon32x32 from "../../images/favicon/favicon-32x32.png";
import faviconAppleTouch from "../../images/favicon/apple-touch-icon.png";

const faviconLinks = [
  { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16x16 },
  { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32x32 },
  { rel: "apple-touch-icon", type: "image/png", href: faviconAppleTouch },
];

Seo.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
  title: ``,
  noIndex: false
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  noIndex: PropTypes.bool
}

function Seo({ description, lang, meta, title, image, isArticle, children, author, date, slug, noIndex }) {
  const { site } = useStaticQuery(query)

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title ? `${title} | MOVE Dungarvan` : site.siteMetadata.title
  const metaImage = image ? site.siteMetadata.siteUrl + image : site.siteMetadata.logo

  // Initial breadcrumb list
  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': site.siteMetadata.siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let structuredData = null

  let url = `${site.siteMetadata.siteUrl}`

  if (isArticle) {
    url = `${site.siteMetadata.siteUrl}/${slug}`

    structuredData = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: new Date().getFullYear(),
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: "MOVE Health & Fitness",
        logo: {
          '@type': 'ImageObject',
          url: site.siteMetadata.logo,
          width: 650,
          height: 650
        },
      },
      datePublished: date,
      dateModified: site.buildTime,
      description: metaDescription,
      headline: title,
      url: url,
      name: title,
      image: {
        '@type': 'ImageObject',
        url: metaImage,
        width: 650,
        height: 650
      },
      mainEntityOfPage: url,
    }

    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': url,
        name: title,
      },
      position: 2,
    })

  } else {
    structuredData = {
      '@context': 'http://schema.org',
      '@type': 'ExerciseGym',
      name: metaTitle,
      legalName: 'Move Health and Fitness Limited',
      image: {
        '@type': 'ImageObject',
        url: site.siteMetadata.logo,
        width: 650,
        height: 650
      },
      logo: {
        '@type': 'ImageObject',
        url: site.siteMetadata.logo,
        width: 650,
        height: 650
      },
      description: metaDescription,
      url: url,
      telephone: site.siteMetadata.telephone,
      priceRange: "$$",
      foundingDate: '2020',
      foundingLocation: 'Ireland',
      founders: [
        {
          "@type": "Person",
          "name": "Kenneth Murphy"
        }
      ],
      contactPoint: [
        {
          "@type": "ContactPoint",
          "email": "info@move-dungarvan.ie",
          "telephone": site.siteMetadata.telephone,
          "contactType": "customer service"
        }
      ],
      address: {
        "@type": "PostalAddress",
        "addressLocality": "5 Applegate Centre, Shandon, Dungarvan",
        "addressRegion": "Co. Waterford",
        "addressCountry": "Ireland",
        "postalCode": "X35 C973",
      },
      geo: {
        "@type": "GeoCoordinates",
        "latitude": 52.097026,
        "longitude": -7.629824
      },
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "06:00",
          "closes": "21:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Saturday",
          ],
          "opens": "09:00",
          "closes": "15:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Sunday",
          ],
          "opens": "09:00",
          "closes": "13:30"
        }
      ],
      sameAs: [
        "https://www.facebook.com/movedungarvan/",
        "https://www.instagram.com/movedungarvan/",
        "https://twitter.com/MoveDungarvan"
      ]
    }
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={`%s`}
        link={faviconLinks}
        meta={[
          {
            name: "description",
            content: metaDescription
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: "og:image",
            content: metaImage
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: "og:site_name",
            content: site.siteMetadata.title
          },
          {
            property: `twitter:card`,
            content: `summary_large_image`
          },
          {
            property: `twitter:image`,
            content: metaImage
          },
          {
            property: `twitter:creator`,
            content: site.siteMetadata.author
          },
          {
            property: `twitter:title`,
            content: metaTitle
          },
          {
            name: "twitter:site",
            content: site.siteMetadata.twitter
          },
          {
            name: "keywords",
            content:
              "Move Health & Fitness, MOVE Health & Fitness, Gym, Dungarvan, Gym Dungarvan, Waterford, Munster, Ireland, Greenway, Fitness, Fitness Classes, Sports Rehab, Training, Team"
          },
          {
            name: "viewport",
            content: "minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          }
        ].concat(meta)}>
        
        <link rel="canonical" href={url} />
        <script className={"structured-data"} type="application/ld+json">{JSON.stringify(structuredData)}</script>	
        <script className={"structured-data"} type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        
        { noIndex &&
          <meta name="robots" content="noindex,nofollow,noarchive" />
        }

      </Helmet>
      <section>{children}</section>
    </div>
  )
}

const query = graphql`
query SiteDataQuery {
  site {
    siteMetadata {
      title
      author
      description
      siteUrl
      logo
      telephone
    }
  }
}
`

export default Seo
