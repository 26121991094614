import React from "react"
import * as styles from "./footer.module.css"
import Social from "../social/social"
import logo from "./../../images/logo-white.svg"

function Footer() {
    return (
        <footer className={styles.footer} id="footer">
            <section className={styles.footerGrid}>
              <img className={styles.footerLogo} src={logo} alt="Move Health and Fitness Logo"/>
              <Social />
            </section>

            <section className={styles.footerLegal}>
                <p><a href="/privacy-policy.html">Privacy Policy</a></p>
                <p> &#47;&#47; </p>
                <p><a href="/terms.html">Terms</a></p>
            </section>


            <p className={styles.copyright}>Copyright Move Health &amp; Fitness Limited</p>
            
            <section className={styles.createdBy}>
                <p>Website by <a href="https://round-tower.ie">Round Tower</a></p>
            </section>
        </footer>
    )
}

export default Footer